export const NAV_ITEM = [
  {
    name: 'About',
    slug: '/service,/value,/message,/people',
    href: '/about',
    children: [
      { name: '事業内容', slug: '', href: '/service' },
      { name: '私たちの価値観', slug: '', href: '/value' },
      { name: '創業者メッセージ', slug: '', href: '/message' },
      { name: 'SUPER STUDIOで働く人たち', slug: '', href: '/people' },
    ],
  },
  {
    name: 'Work style',
    slug: '/carrier,/workstyle,/benefits',
    href: '/work-style',
    children: [
      {
        name: '評価制度・キャリア支援',
        slug: 'carrier',
        href: '/carrier',
      },
      {
        name: '働く環境',
        slug: 'workstyle',
        href: '/workstyle',
      },
      {
        name: '福利厚生',
        slug: 'benefits',
        href: '/benefits',
      },
      {
        name: 'データで見るSUPER STUDIO',
        slug: 'data',
        href: '/data',
      },
    ],
  },
  {
    name: 'Interview',
    slug: 'interview',
    href: '/interview',
  },
  {
    name: 'Job listings',
    slug: 'job-listings',
    href: '/jobs',
  },
  {
    name: 'Faq',
    slug: 'faq',
    href: '/faq',
  },
  {
    name: '新卒採用',
    slug: 'newgraduate',
    href: '/newgraduate',
  },
]

export const FOOTER_NAV = [
  {
    name: 'about',
    list: [
      { name: '事業内容', url: '/service' },
      { name: '私たちの価値観', url: '/value' },
      { name: '創業者メッセージ', url: '/message' },
      { name: 'SUPER STUDIOで働く人たち', url: '/people' },
    ],
  },
  {
    name: 'Work style',
    list: [
      { name: '評価制度・キャリア支援', url: '/carrier' },
      { name: '働く環境', url: '/workstyle' },
      { name: '福利厚生', url: '/benefits' },
      {
        name: 'データで見るSUPER STUDIO',
        url: '/data',
      },
    ],
  },
  {
    name: 'interview',
    list: [{ name: 'インタビュー', url: '/interview' }],
  },
  {
    name: 'job listings',
    list: [{ name: '職種一覧', url: '/jobs' }],
  },
  {
    name: 'faq',
    list: [{ name: 'よくあるご質問', url: '/faq' }],
  },
]

export const FOOTER_BOTTOM_NAV = [
  {
    name: 'cookie policy',
    url: 'https://www.super-studio.jp/cookie',
  },
  {
    name: 'privacy policy',
    url: 'https://www.super-studio.jp/privacy',
  },
  {
    name: 'customer harassment policy',
    url: 'https://www.super-studio.jp/customer',
  },
  {
    name: 'information security',
    url: 'https://www.super-studio.jp/security',
  },
] as const
export const TOP_JOB_SESSION = [
  {
    title: 'コンサルタント',
    description:
      '自社D2Cブランドのノウハウを活かした商品企画やマーケティング・販促支援、およびデータソリューションの導入における基盤構築支援や戦略的なデータ活用支援などを行います。',
    images: '/images/top/job_07.webp',
    type: ['デジタル戦略コンサルタント', 'EC/D2Cコンサルタント'],
    href: 'https://hrmos.co/pages/superstudio/jobs/00000007',
  },
  {
    title: 'プロジェクトマネージャー',
    description:
      '他社ECシステムから「ecforce」へ移行する際のプロジェクトマネジメントや、データソリューションの導入支援を行います。',
    images: '/images/job_category/job_04.webp',
    type: ['システム導入PM', 'システム導入SE'],
    href: 'https://hrmos.co/pages/superstudio/jobs/00000004',
  },
  {
    title: 'エンジニア',
    description:
      '「ecforce」の機能開発やデータソリューションなどの新規プロダクト開発、また、システムの安定稼働に向けて必要な運用を行います。',
    images: '/images/job_category/job_09.webp',
    type: [
      'フロントエンドエンジニア',
      'バックエンドエンジニア',
      'フルスタックエンジニア',
      'SRE',
      'セキュリティエンジニア',
    ],
    href: 'https://hrmos.co/pages/superstudio/jobs/00000010',
  },
]
export const IMG_SLG = [
  {
    images: '/images/about/service/slg_icon_1.png',
  },
  {
    images: '/images/about/service/slg_icon_2.png',
  },
  {
    images: '/images/about/service/slg_icon_3.png',
  },
  {
    images: '/images/about/service/slg_icon_4.png',
  },
  {
    images: '/images/about/service/slg_icon_5.png',
  },
]
export const FAQ = [
  {
    id: 1,
    question: '選考プロセスについて詳しく教えてください',
    answer:
      '<p>選考プロセスは以下の通りとなります。<br/>・書類選考<br/>・適性検査（ポジションによって課題を設ける可能性があります）<br/>・面接（2〜3回程度）<br/>・リファレンスチェック<br/>・オファー面談</p><br/><small>※書類選考からオファー面談までに最短2週間ほど、平均約1ヶ月〜2ヶ月の期間をいただいております。<br/>※面談/面接はオンラインか弊社オフィスでの実施をお選びいただけます。<br/>※選考途中でカジュアル面談やランチ面談などを挟ませていただくケースもございます。</small><picture><source media="(max-width: 769px)" srcset="./images/faq/answer_1_sp.webp"/><img src="./images/faq/answer_1.webp"/></picture>',
  },
  {
    id: 2,
    question: 'リモート勤務は可能ですか',
    answer:
      '<p>ご自宅でのリモートワークは可能です。場所に縛られない働き方が選択できるため、育児や介護と両立しながら安心して働くことができます。</p><br/><small>※リモートワークとオフィス出社の頻度は、配属部署によって異なります。</small>',
  },
  {
    id: 3,
    question: '社内でのコミュニケーションはどのツールを活用していますか',
    answer:
      '<p>主に、Slackというメッセージングアプリで、コミュニケーションを取っています。また、MetaLifeというバーチャルオフィスを用いたコミュニケーションツールも導入しており、リモートワークでも気軽に会話できる環境です。</p>',
  },
  {
    id: 4,
    question: '新卒採用者と中途採用者の比率を教えてください',
    answer:
      '<p>直近3年では以下の通りの比率です。<br />2021年度（2021年12月〜2022年11月）：中途94.7%、新卒5.3％<br />2022年度（2022年12月〜2023年11月）：中途95.6%、新卒4.4％<br />2023年度（2023年12月〜2024年11月）：中途84.5％、新卒15.5％</p><br/><small>※公表日：2025年2月10日</small>',
  },
]

export const OUR_VOICE_REASON = [
  {
    text: '選考の時に対応してもらった皆さんの人柄に惹かれたからです。<br/>また、成長中の企業であるため裁量を持って色々な経験ができると思い入社しました。',
    tag: '#セールス',
    image: '/images/face_01.webp',
  },
  {
    text: 'SaaSとして急成長しているプロダクトを持っており、メンバー全員が一丸となってお客様へのサービス価値を提供できる環境だと感じたからです。',
    tag: '#エンジニア',
    image: '/images/face_02.webp',
  },
  {
    text: '元々興味があったものづくりを身近に感じられる環境であることや、面接を進める中で感じた人の良さに惹かれて入社しました。',
    tag: '#セールス',
    image: '/images/face_03.webp',
  },
  {
    text: '新しい機能やサービスが開発されていくスピード感に魅力を感じました。<br/>成長中の企業のため様々なことに挑戦できるのではないかと思い入社を決めました。',
    tag: '#広報',
    image: '/images/face_04.webp',
  },
  {
    text: '一緒に働く「人」で決めました。<br/>面接の時もありのままの自分で会話することができ、この人たちと一緒に頑張ろうと思えたことが入社の決め手でした。',
    tag: '#人事',
    image: '/images/face_05.webp',
  },
  {
    text: '業界の中でも先進的で、EC事業に必要不可欠なプロダクトを展開していることに魅力を感じ、自分自身の経験も活かせると思ったからです。',
    tag: '#カスタマーサクセス',
    image: '/images/face_01.webp',
  },
  {
    text: '社員の自然体な雰囲気を感じ、良いコミュニケーションが取れると思いました。<br/>また、ecforceが掲げるECの未来にも共感できました。',
    tag: '#デザイナー',
    image: '/images/face_02.webp',
  },
  {
    text: 'ecforceを軸に自社D2Cブランドの展開とコンサルティングサービスを提供しており、優れたビジネスモデルであると感じて入社を決意しました。',
    tag: '#セールス',
    image: '/images/face_03.webp',
  },
  {
    text: '面接の時に候補者に対するリスペクトを感じられたことが理由でした。<br/>対等に話をしてもらい入社後のイメージが湧いたため入社を決めました。',
    tag: '#カスタマーサポート',
    image: '/images/face_04.webp',
  },
  {
    text: '自分が描くキャリアを実現できると思ったからです。<br/>また、COOの花岡さんのnoteを拝読して非常に信頼できると感じ、会社の未来にもワクワクしました。',
    tag: '#プロジェクトマネージャー',
    image: '/images/face_05.webp',
  },
]

export const OUR_VOICE_IGNITE = [
  {
    text: '自分が考えた施策が軌道に乗り、自社D2C事業で売上が大きく伸びたとき。<br/>また、新しいことにチャレンジできたとき。',
    tag: '#プロジェクトサポート',
    image: '/images/face_01.webp',
  },
  {
    text: 'タクシーやYouTubeなどでecforceのCMを見たとき。<br/>見るたびにクリエイティブの完成度に感動します。',
    tag: '#プロジェクトマネージャー',
    image: '/images/face_02.webp',
  },
  {
    text: 'ecforceのクライアントへの取材を通して、SUPER STUDIOの取り組みが世の中の役に立っていることを実感できたとき。',
    tag: '#広報',
    image: '/images/face_03.webp',
  },
  {
    text: 'メンバー全員で意見を出し合い、最適なコンセンサスを得られたとき。<br/>また、それをクライアントに喜んでいただけたとき。',
    tag: '#PMM',
    image: '/images/face_04.webp',
  },
  {
    text: 'クライアントのEC事業の規模が大きくなり、SUPER STUDIO・ecforceの介在価値を感じたとき。',
    tag: '#セールス',
    image: '/images/face_05.webp',
  },
  {
    text: 'プロジェクトを進める際、メンバーが積極的に知見を共有してくれたことで、無事に問題が解決できたとき。',
    tag: '#エンジニア',
    image: '/images/face_01.webp',
  },
  {
    text: '高いチーム目標を全員が努力して達成できたとき。<br/>最後まで諦めずに取り組んだからこそ、達成できたときはとても嬉しかったです。',
    tag: '#セールス',
    image: '/images/face_02.webp',
  },
  {
    text: 'ecforceの機能を組み合わせて、お客様にとって最適な施策を提案できたとき。',
    tag: '#PMM',
    image: '/images/face_03.webp',
  },
  {
    text: '難易度の高い案件にチャレンジできたとき。<br/>自分のスキルをフル活用して挑戦することができました。',
    tag: '#カスタマーサポート',
    image: '/images/face_04.webp',
  },
  {
    text: 'クライアントのEC事業に貢献できる提案ができたとき。<br/>その結果、感謝の言葉をいただけたとき。',
    tag: '#セールス',
    image: '/images/face_05.webp',
  },
]

export const TOP_SLIDE_LOOP = [
  '/images/top/end_01.webp',
  '/images/top/end_02.webp',
  '/images/top/end_03.webp',
  '/images/top/end_04.webp',
  '/images/top/end_05.webp',
]

export const TOP_CAREER = [
  {
    text: '新卒採用',
    textColor: 'text-white',
    imagePc: '/images/top/career_01_pc.webp',
    imageSp: '/images/top/career_01_sp.webp',
    href: '/newgraduate',
  },
  {
    text: 'カジュアル面談',
    textColor: 'text-darkgrey_900',
    imagePc: '/images/top/career_02_pc.webp',
    imageSp: '/images/top/career_02_sp.webp',
    href: 'https://hrmos.co/pages/superstudio/jobs/0010018/apply',
  },
]
export const VALUE_CULTURE = [
  {
    id: 1,
    title: '変わっていくことを楽しもう',
    image: '/images/about/value/item_cultute_1.webp',
    detail:
      '会社の成長に合わせて事業も組織も変化し、個に求められる要件も上がっていく。進化のために変化し続けることは容易ではないが、それが個の成長に繋がっていく。現状に満足せず、時には自分自身を疑い、成長のための変化を楽しもう。',
  },
  {
    id: 2,
    title: 'オーナーシップを持とう',
    image: '/images/about/value/item_cultute_2.webp',
    detail:
      '一人ひとりが主体性と責任感を持ち、できることから行動しよう。その勇気ある行動が多くの仲間を巻き込み、たくさんのアイデアを生み出し、挑戦を成功に導く。オーナーシップがあるからこそ、成功で得られる感動も失敗から得られる学びにも意味がある。',
  },
  {
    id: 3,
    title: '一人ひとりが細部にこだわろう',
    image: '/images/about/value/item_cultute_3.webp',
    detail:
      '言葉選び一つ、デザイン一つ、コード一つ。無駄な仕事なんて何一つない。その細部にこだわることこそが、真のプロフェッショナルである。一人ひとりのプロフェッショナルな仕事が大きな成果をもたらし、社会にも大きなインパクトを与えていく。',
  },
  {
    id: 4,
    title: '現場の第一人者になろう',
    image: '/images/about/value/item_cultute_4.webp',
    detail:
      '解決すべき課題は常に現場にある。現場を体験することでしか得られない解像度を持って、本質的な課題に徹底的に向き合おう。その本質的な課題解決こそ、世の中を変える可能性を秘めている。',
  },
  {
    id: 5,
    title: '確信をとりにいこう',
    image: '/images/about/value/item_cultute_5.webp',
    detail:
      'プロダクト、会社への確信。確信があるから成果が最大化される。その確信を得るために自ら情報を取りに行こう。会社はそのために必要な情報開示を約束する。そして顔を合わせて、たくさん議論しよう。情熱のあるコミュニケーションから生まれた確信は、会社にも社会にも大きなインパクトを与える。',
  },
  {
    id: 6,
    title: '情熱と思いやりでヒトを動かそう',
    image: '/images/about/value/item_cultute_6.webp',
    detail:
      'いつの時代もヒトを動かすのは情熱と思いやりだ。圧倒的な情熱と多様な価値観を受け入れる思いやりは、多くのヒトに伝播し、物事を必ずや成功に導く。皆がワクワクしながらする仕事は何物にも変え難い財産となる。',
  },
  {
    id: 7,
    title: '三方よしの選択を追求しよう',
    image: '/images/about/value/item_cultute_7.webp',
    detail:
      '三方（顧客・会社・社会）にとって最善の選択を追求しよう。どれか一つでなく「すべて」が取れる可能性を諦めない。前提や常識を疑い、あるべき姿を皆で再定義し続けよう。三方よしの選択は長期的な利益を生み、それは自分自身にとっても最高の成果となる。',
  },
]
export const VALUE_VALUE_SESSION = [
  {
    title: 'CHANGE',
    desc: '変われる人であれ',
    poster: '/images/about/value/value_1_pc.webp',
    src_webm: '/videos/change.webm',
    src_mp4: '/videos/change.mp4',
  },
  {
    title: 'INSIGHT',
    desc: '本質を見極めろ',
    poster: '/images/about/value/value_2_pc.webp',
    src_webm: '/videos/insight.webm',
    src_mp4: '/videos/insight.mp4',
  },
  {
    title: 'HONESTY',
    desc: '人格者であれ',
    poster: '/images/about/value/value_3_pc.webp',
    src_webm: '/videos/honesty.webm',
    src_mp4: '/videos/honesty.mp4',
  },
]
export const ABOUT_CONTENT_MESSAGE = [
  {
    title: '互いの強みにフォーカスしたリスペクトのある組織',
    desc: 'SUPER STUDIOは、創業期から人の強みにフォーカスした組織づくりを目指してきました。<br/>創業者の4人はそれぞれ得意・不得意な領域が異なりますが、今もなお4人が誰一人欠けることなく、全員がコミットしている状態にあります。<br/>互いの弱みに目を向ければ、いかようにも別々の道を歩むことはできたと思います。<br/><br/>しかし、私たちは成長のために変化し、互いをリスペクトすることで、各々の強みにフォーカスした言動をとってきたからこそ、今があると確信しています。<br/>これは今でも変わらず、SUPER STUDIOのDNAとして会社全体に浸透しています。',
  },
  {
    title: '人の成長が企業と社会の成長につながる',
    desc: 'SUPER STUDIOの成長は、共に働くメンバー一人ひとりの成長によって実現されてきました。<br/>中には、立場によって強制的に目線を上げ、短期で圧倒的な成長を遂げる人たちも多くいます。<br/>だからこそ私たちは、情熱と思いやりを持つ人と徹底的に向き合い、挑戦する人には機会を提供し、成果を出した人に適切に報いることを約束します。<br/><br/>人の成長がSUPER STUDIOの成長となり、VISION/MISSIONの実現はもちろん、世の中を変える大きな力になると確信しています。',
  },
  {
    title: '心に情熱の火を灯し合える仲間と仕事がしたい',
    desc: '何かを成し遂げるためには必ず人の熱い想い「情熱」が必要です。<br/>一人の情熱が多くの共感を生み、それは人を巻き込む力になります。チームとして互いを尊重しながら取り組む仕事は何よりも楽しく、それは圧倒的な成果を生み出します。この体験やプロセスこそが、仕事をする上で何ものにも代えがたい財産になります。<br/><br/>SUPER STUDIOのタグライン「IGNITE YOUER HEART」の通り、互いの心に情熱の火を灯し合える仲間と共に働き、成長し、より大きなことを成し遂げていきたいと考えています。',
  },
]
export const FOUNDING_MEMBERS = [
  {
    image: '/images/about/ceo.webp',
    position: '代表取締役社長 CEO',
    name_jp: '林 紘祐',
    name_en: 'KOSUKE HAYASHI',
    desc: '1987年、大阪府出身。関西大学卒業。Web広告代理店にマーケターとして入社後、ECサイトやサービスのグロースを多数担当。2014年にSUPER STUDIOを共同創業し、同社CEOに就任。現在は新規事業開発や外部アライアンスを率いる。',
    id: 1,
  },
  {
    image: '/images/about/coo.webp',
    position: '取締役副社長 COO 兼 CPO （共同創業者）',
    name_jp: '花岡 宏明',
    name_en: 'HIROAKI HANAOKA',
    desc: '1987年、大阪府出身。関西大学を卒業後、国内大手SI企業にて大規模金融基幹システムの運用を担当。2014年にSUPER STUDIOを共同創業し、同社取締役に就任。現在はCOO兼CPOとしてSUPER STUDIOの事業全体を統括しながら、ecforceのプロダクト責任者も務める。',
    id: 2,
  },
  {
    image: '/images/about/cto.webp',
    position: '取締役 CTO （共同創業者）',
    name_jp: '村上 功記',
    name_en: 'KOKI MURAKAMI',
    desc: '1986年、大阪府出身。関西大学を卒業後、ロサンゼルスでオンライン決済サービスや大手自動車機器メーカーの基幹システムの設計・開発を担う。SUPER STUDIO共同創業メンバーとして、2015年に同社取締役に就任。現在はCTOとして開発チームを率いる。',
    id: 3,
  },
  {
    image: '/images/about/cro.webp',
    position: '取締役 CRO （共同創業者）',
    name_jp: '真野 勉',
    name_en: 'TSUTOMU MANO',
    desc: '1987年、東京都出身。青山学院大学を卒業後、ITベンチャー企業へ入社し、セールスとして同社の東証マザーズ上場に貢献。2014年にSUPER STUDIOを共同創業し、現在はCROとして企業間のアライアンスをリードしている。',
    id: 4,
  },
]

export const FAQ_SELECTION_PROCESS = [
  {
    id: 1,
    question: '選考プロセスについて詳しく教えてください。',
    answer:
      '<p>選考プロセスは以下の通りとなります。<br/>・書類選考<br/>・適性検査（ポジションによって課題を設ける可能性があります）<br/>・面接（2〜3回程度）<br/>・リファレンスチェック<br/>・オファー面談</p><br/><small>※書類選考からオファー面談までに最短2週間ほど、平均約1ヶ月〜2ヶ月の期間をいただいております。<br/>※面談/面接はオンラインか弊社オフィスでの実施をお選びいただけます。<br/>※選考途中でカジュアル面談やランチ面談などを挟ませていただくケースもございます。</small><picture><source media="(max-width: 769px)" srcset="./images/faq/answer_1_sp.webp"/><img src="./images/faq/answer_1.webp"/></picture>',
  },
  {
    id: 2,
    question: '複数ポジションへのエントリーは可能ですか',
    answer:
      '<p>複数ポジションへのエントリーは可能です。<br />書類選考から面談/面接の過程でご相談しながらポジションを決めさせていただきます。<br />また、応募ポジションにお悩みの場合は、カジュアル面談からスタートいただくことも可能です。</p>',
  },
  {
    id: 3,
    question: '今すぐの転職を考えているわけではありませんが、応募可能ですか',
    answer:
      '<p>もちろん可能です。お気軽にご相談ください。<br/>本選考に進む前にお話を聞いてみたいという方は、カジュアル面談も実施しております。</p>',
  },
  {
    id: 4,
    question: 'EC業界について知識がなくても、エントリーは可能ですか',
    answer:
      '<p>エントリー可能です。SUPER STUDIOでは、EC未経験で入社し活躍しているメンバーが多数います。</p>',
  },
  {
    id: 5,
    question: '外国籍の採用は行っていますか',
    answer:
      '<p>外国籍の方もエントリーいただけます。<br />その場合、ビジネスレベルの日本語能力が必須要件となります。</p>',
  },
]

export const FAQ_WORK_STYLE = [
  {
    id: 1,
    question: 'リモート勤務は可能ですか',
    answer:
      '<p>ご自宅でのリモートワークは可能です。場所に縛られない働き方が選択できるため、育児や介護と両立しながら安心して働くことができます。</p><br/><small>※リモートワークとオフィス出社の頻度は、配属部署によって異なります。</small>',
  },
  {
    id: 2,
    question: '社内での情報共有は、どのようになされていますか',
    answer:
      '<p>社員全員が集まる月次全社定例を実施し、各部署の取り組みや実績などを報告しています。また、経営方針やサービスリリースに関する共有会や説明会なども適宜行われるため、情報の透明性が保たれています。</p>',
  },
  {
    id: 3,
    question: '社内でのコミュニケーションはどのツールを活用していますか',
    answer:
      '<p>主に、Slackというメッセージングアプリで、コミュニケーションを取っています。また、MetaLifeというバーチャルオフィスを用いたコミュニケーションツールも導入しており、リモートワークでも気軽に会話できる環境です。</p>',
  },
  {
    id: 4,
    question: '業務上のデバイス環境を教えてください',
    answer:
      '<p>入社時、全員にMacBookを貸与しております。その他、サブモニター、社用携帯、各種周辺機器も貸与します。</p><br/><small>※MacBookは配属部署によってスペックが異なります。</small>',
  },
]

export const FAQ_COMPANY = [
  {
    id: 1,
    question: '新卒採用者と中途採用者の比率を教えてください',
    answer:
      '<p>直近3年では以下の通りの比率です。<br />2021年度（2021年12月〜2022年11月）：中途94.7%、新卒5.3％<br />2022年度（2022年12月〜2023年11月）：中途95.6%、新卒4.4％<br />2023年度（2023年12月〜2024年11月）：中途84.5％、新卒15.5％</p><br/><small>※公表日：2025年2月10日</small>',
  },
  {
    id: 2,
    question: '入社日はいつですか',
    answer:
      '<p>毎月1日と15日を入社日として設けています。</p><br/><small>※1日・15日が土日祝日と被る場合、翌営業日を入社日としております。</small>',
  },
  {
    id: 3,
    question: '社内イベントはどんなものが行われていますか',
    answer:
      '<p>年に1回、全社員が集まる「KICK OFF」を開催しています。懇親会等も含め、非常に大きなイベントです。その他採用イベントなども定期的に実施しております。</p>',
  },
]
export const WORKING_ENVIRONMENT_LIST = [
  {
    title: '勤務時間を選べる',
    intro: 'フレックスタイムの導入',
    imagePc: '/images/workstyle/workstyle_01.webp',
    imageSp: '/images/workstyle/workstyle_01_sp.webp',
    description:
      '勤務時間はコアタイム（11:00～15:00）、フレキシブルタイム（7:00〜11:00 / 15:00〜22:00）内で調整できる制度を設けているため、柔軟な働き方が可能です。',
  },
  {
    title: '勤務場所を選べる',
    intro: 'ハイブリットワークの実現',
    imagePc: '/images/workstyle/workstyle_02.webp',
    imageSp: '/images/workstyle/workstyle_02_sp.webp',
    description:
      'オフィスワークとリモートワークを組み合わせた勤務が可能です。場所に縛られない働き方が選択できるため、出産や育児、介護などのほか、やむを得ない事情があっても安心して働くことができます。',
  },
  {
    title: '1時間単位での有給休暇取得',
    intro: '短時間の用事などにも対応可能',
    imagePc: '/images/workstyle/workstyle_03.webp',
    imageSp: '/images/workstyle/workstyle_03_sp.webp',
    description:
      '1時間単位で有給休暇の取得が可能です。お子さんの行事への参加や家族の介護、通院など理由を問わず、効率的な勤務時間を実現する制度です。仕事とプライベートの両立を可能とします。',
  },
  {
    title: '産前・産後休業、育児休業制度の整備',
    intro: '役職や性別を問わない育児休暇取得を推進',
    imagePc: '/images/workstyle/workstyle_04.webp',
    imageSp: '/images/workstyle/workstyle_04_sp.webp',
    description:
      '出産・育児に向き合える環境づくりのため、産前産後休業や育児休業制度を整えています。育児休業制度については、これまで累計28名の取得実績があります。（2023年11月時点）',
  },
]
export const JOB_CATEGORY = [
  {
    title: 'セールス',
    description: 'EC/D2C事業者に対して「ecforce」の導入提案を行います。',
    images: '/images/job_category/job_01.webp',
    type: [
      'フィールドセールス',
      'インサイドセールス',
      'エンタープライズセールス',
      'パートナーセールス',
    ],
    href: 'https://hrmos.co/pages/superstudio/jobs/00000001',
  },
  {
    title: 'カスタマーサポート',
    description:
      '主に「ecforce」導入企業のシステム利用に関するお問い合わせや、EC/D2C運営に関するあらゆるお問い合わせをサポートします。',
    images: '/images/job_category/job_02.webp',
    type: ['カスタマーサポート'],
    href: 'https://hrmos.co/pages/superstudio/jobs/00000002',
  },
  {
    title: 'カスタマーサクセス',
    description:
      '「ecforce」の導入支援や、EC/D2C事業者の売上向上に関わる施策提案から実行まで、包括的な支援を行います。',
    images: '/images/job_category/job_03.webp',
    type: [
      'カスタマーサクセス',
      'カスタマーオンボーディング',
      'カスタマーマーケティング',
    ],
    href: 'https://hrmos.co/pages/superstudio/jobs/00000003',
  },
  {
    title: 'プロジェクトマネージャー',
    description:
      '他社ECシステムから「ecforce」へ移行する際のプロジェクトマネジメントや、データソリューションの導入支援を行います。',
    images: '/images/job_category/job_04.webp',
    type: ['システム導入PM', 'システム導入SE'],
    href: 'https://hrmos.co/pages/superstudio/jobs/00000004',
  },
  {
    title: 'PdM・PMM',
    description:
      '「ecforce」の開発ロードマップ策定や、プロダクトマーケティング戦略の立案・推進を行います。',
    images: '/images/job_category/job_05.webp',
    type: [
      'プロダクトマネージャー（PdM）',
      'プロダクトマーケティングマネージャー（PMM）',
    ],
    href: 'https://hrmos.co/pages/superstudio/jobs/00000005',
  },
  {
    title: 'サポート事務',
    description:
      '「ecforce」導入企業に円滑なサービス提供を行うため、各部署でサポート業務を行います。',
    images: '/images/job_category/job_06.webp',
    type: ['営業事務'],
    href: 'https://hrmos.co/pages/superstudio/jobs/00000006',
  },
  {
    title: 'コンサルタント',
    description:
      '自社D2Cブランドのノウハウを活かした商品企画やマーケティング・販促支援、およびデータソリューションの導入における基盤構築支援や戦略的なデータ活用支援などを行います。',
    images: '/images/job_category/job_07.webp',
    type: ['デジタル戦略コンサルタント', 'EC/D2Cコンサルタント'],
    href: 'https://hrmos.co/pages/superstudio/jobs/00000007',
  },
  {
    title: 'マーケター',
    description:
      'BtoB領域でのマーケティング戦略の立案から実行までを行います。また、自社D2Cブランドの運営を担うこともあります。',
    images: '/images/job_category/job_08.webp',
    type: ['BtoB SaaSマーケター', 'D2Cブランドマネージャー'],
    href: 'https://hrmos.co/pages/superstudio/jobs/00000009',
  },
  {
    title: 'エンジニア',
    description:
      '「ecforce」の機能開発やデータソリューションなどの新規プロダクト開発、また、システムの安定稼働に向けて必要な運用を行います。',
    images: '/images/job_category/job_09.webp',
    type: [
      'フロントエンドエンジニア',
      'バックエンドエンジニア',
      'フルスタックエンジニア',
      'SRE',
      'セキュリティエンジニア',
    ],
    href: 'https://hrmos.co/pages/superstudio/jobs/00000010',
  },
  {
    title: 'デザイナー',
    description:
      '「ecforce」や自社D2Cブランド、コーポレートブランディングなど、コミュニケーションデザインからUI/UX設計までデザイン全般をリードします。',
    images: '/images/job_category/job_10.webp',
    type: ['SaaSプロダクトデザイナー（UI/UX）', 'コミュニケーションデザイナー'],
    href: 'https://hrmos.co/pages/superstudio/jobs/00000011',
  },
  {
    title: '採用・人事',
    description:
      '各部署に必要な人材を確保し、活躍するための仕組みづくりや環境を整備します。',
    images: '/images/job_category/job_11.webp',
    type: ['中途採用', '新卒採用', '人事企画', '人事労務'],
    href: 'https://hrmos.co/pages/superstudio/jobs/00000012',
  },
  {
    title: '経営企画・法務総務',
    description:
      '経営企画･法務･総務など様々な管理をしながら、急成長する会社を安全に運営するためのサポートを行います。',
    images: '/images/job_category/job_12.webp',
    type: ['経営企画', '経理', '法務', '総務'],
    href: 'https://hrmos.co/pages/superstudio/jobs/00000013',
  },
]
export const PEOPLE_MEMBER_SESSION = [
  {
    id: 1,
    name: '吉田 光',
    group: '人事戦略室<br/>人事',
    description:
      '部署の垣根を越え思想が合うと感じるシーンが多く、この人達となら多くを乗り越えられそうと思った時。<br/>また、自分が普段好きなブランドがecforceを利用して売上を伸ばすことができた時。',
    image: '/images/about/people/member_1.webp',
    image_hover: '/images/about/people/member_1_hover.webp',
    image_modal: '/images/about/people/member_1_modal.webp',
    info: [
      {
        reason:
          '面接中や執務室で会った人たちがイキイキしていて役職を越えて和気藹々とした雰囲気だったことで自分らしく働くイメージが湧いたため。また、会社のVALUEに共感できたから。',
        interest:
          'ゴールを明確に決め逆算して達成へ動くことの推進力 / あらゆる人と協調しながら働けるしなやかさ',
        company: '化粧品業界',
      },
    ],
  },
  {
    id: 2,
    name: '後藤 智行',
    group: 'セールス&マーケティング本部<br/>フィールドセールス',
    description:
      '会社の事業戦略や方針がスピーディーに実現していることを感じながら仕事ができ、その実現に自分自身が携われていること。',
    image: '/images/about/people/member_2.webp',
    image_hover: '/images/about/people/member_2_hover.webp',
    image_modal: '/images/about/people/member_2_modal.webp',
    info: [
      {
        reason:
          '開発サイドとセールスの距離が近く、密な連携で戦略を実行できていることや、自社でD2Cブランドの運営を行いながらプロダクトのアップデートしてくことに合理性と魅力を感じたため。',
        interest:
          '新しい市場を開拓していくことと、そのプロセスでの仮説検証の繰り返しを行うことで、プロダクトの提供価値を広げること。',
        company: 'IT業界',
      },
    ],
  },
  {
    id: 3,
    name: '行方 渉',
    group: 'カスタマー本部<br/>カスタマーサポート',
    description:
      '事業の最適化に向けた様々な意思決定が早いスピードで行われ、施策が実行されていると感じた時。<br/>また、素早い意思決定の背景に会社メンバーの共通認識があると感じた時。',
    image: '/images/about/people/member_3.webp',
    image_hover: '/images/about/people/member_3_hover.webp',
    image_modal: '/images/about/people/member_3_modal.webp',
    info: [
      {
        reason:
          '面接をしてもらった方の雰囲気がとても良く、同じ会社で働きたいと思ったため。オフィス見学もさせてもらい、志望度が一気に増したため。',
        interest:
          '各部門のハブとしてプロジェクトを進行すること / 関係部門を集めて最善の運用フローの構築・見直しをすること',
        company: '保険業界',
      },
    ],
  },
  {
    id: 4,
    name: '大沼 竜平',
    group: 'セールス&マーケティング本部<br/>ECコンサル',
    description:
      'セールスサイドとプロダクトサイドが同じ方向を向いて戦略を描き、実行し、顧客の事業に貢献できた時。<br/>統合コマースプラットのフォームの構想が日々具現化していると実感する時。',
    image: '/images/about/people/member_4.webp',
    image_hover: '/images/about/people/member_4_hover.webp',
    image_modal: '/images/about/people/member_4_modal.webp',
    info: [
      {
        reason:
          'toB事業とtoC事業の両立をしていることに共感したため。また、面接で会った方がとても魅力で一緒に働きたいと強く思ったため。',
        interest: 'オーナーシップを持ち部署の垣根を越えて動き回ること',
        company: '広告業界',
      },
    ],
  },
  {
    id: 5,
    name: '細川 貴英',
    group: 'プロダクトエンジニアリング本部<br/>プロダクトエンジニア',
    description:
      '社内で出会う人は例外なく皆、一緒に過ごしている時間が楽しいと感じられる時。<br/>入社して今も変わらず「誰と」働くかということが自分にとっていかに大切か再認識できる時。',
    image: '/images/about/people/member_5.webp',
    image_hover: '/images/about/people/member_5_hover.webp',
    image_modal: '/images/about/people/member_5_modal.webp',
    info: [
      {
        reason:
          '入社前に一年ほど業務委託として関わらせてもらっており、社内やチームの雰囲気が良く魅力的に思ったため。',
        interest: 'プログラミング言語 / 外国語習得など言語全般',
        company: '人材業界',
      },
    ],
  },
  {
    id: 6,
    name: '渕上 航',
    group: 'プロダクト&マーケティング本部<br/>BX/UIデザイナー',
    description:
      '職域を超え、ミッションや難易度が高い施策に一致団結して取り組めた時。<br/>事業の成長スピードや意思決定のスピードが早く、変化が多いこと。',
    image: '/images/about/people/member_6.webp',
    image_hover: '/images/about/people/member_6_hover.webp',
    image_modal: '/images/about/people/member_6_modal.webp',
    info: [
      {
        reason:
          '入社前から業務委託として関わらせてもらっており、その時のプロジェクトが非常に楽しく、また社内メンバーが楽しく働いている姿に魅力を感じたため。',
        interest:
          'デザイン全般 / デザインを事業とつなぎ合わせて価値を出していくこと',
        company: 'クリエイティブ業界',
      },
    ],
  },
]
export const INITIATIVES_SECTION = [
  {
    id: 1,
    name: 'KICK OFF',
    desc: '毎年、新しい期の始まりである12月に、メンバー全員が集まる「KICK OFF」を開催しています。一年の振り返りや中長期戦略の発表、本部やグループ・室ごとの方針発表のほか、期ごとのテーマの発表などが行われます。また、表彰コンテンツ「SUPER STUDIO AWARD」も開催。成果を出したメンバーやCULTUREを体現したメンバーに称賛を贈ることで、さらなるエンゲージメントの向上へと導いています。',
    image: [
      '/images/initiatives/kickoff01.webp',
      '/images/initiatives/kickoff02.webp',
      '/images/initiatives/kickoff03.webp',
      '/images/initiatives/kickoff04.webp',
    ],
  },
  {
    id: 2,
    name: 'SS Club',
    desc: '公式部活動制度である「SS Club」は、共通の趣味を持つメンバー同士が集まり、部活動ごと定期的に活動を行っています。<br class="md:hidden"/>普段業務で関わるメンバー以外とも交流ができるため、社内のコミュニケーションが活性化されています。',
    image: [
      '/images/initiatives/ssclub01.webp',
      '/images/initiatives/ssclub02.webp',
      '/images/initiatives/ssclub03.webp',
      '/images/initiatives/ssclub04.webp',
    ],
  },
  {
    id: 3,
    name: 'SS Channel',
    desc: '社内向けコンテンツとして、「SS Channel」という動画を定期的に配信しています。<br/>「SS Channel」では、普段なかなか聞く機会がない創業メンバーの出会いや休日の過ごし方といったカジュアルなコンテンツから、事業に関する踏み込んだ内容、業界トピックを扱ったニュース形式の配信まで、幅広いコンテンツを扱っています。',
    image: [
      '/images/initiatives/sschannel01.webp',
      '/images/initiatives/sschannel02.webp',
      '/images/initiatives/sschannel03.webp',
      '/images/initiatives/sschannel04.webp',
    ],
  },
  {
    id: 4,
    name: 'むすびNIGHT',
    desc: '「むすびNIGHT」は、メンバーの友人や知人、選考中の候補者の方などが自由に参加できる交流会イベントです。<br/>ケータリングのおにぎりを堪能しながら、SUPER STUDIOのメンバーと交流を深め、オフィスの雰囲気やメンバーの人柄などを体感いただけるよう定期的に開催しています。',
  },
  {
    id: 5,
    name: '社内交流支援',
    desc: '社内コミュニケーション活性化やモチベーションマネジメントのサポートを目的に、新入社員の歓迎会や各部署での懇親会などに社内交際費を活用できます。<br/>メンバー同士のコミュニケーションが円滑になることで、生産性を向上させながら、会社の掲げる目標に一丸となって邁進できる組織づくりを行っています。',
  },
]
export const BENEFIT = [
  {
    name_jp: 'スキルアップ＆キャリア支援',
    name_en: 'SKILL UP & CAREER SUPPORT',
    href: '#',
    list: [
      {
        icon: 'stylus_note',
        title: '資格取得支援制度',
        desc: '会社が定めた資格について、取得や更新等にかかる費用を支援します。',
      },
      {
        icon: 'menu_book',
        title: '書籍購入制度',
        desc: '自己成長を目的とした書籍購入費用を会社が支援します。',
      },
      {
        icon: 'group',
        title: 'キャリア面談',
        desc: 'キャリアコンサルタントの資格を持つ者と面談ができ、キャリア開発をサポートします。',
      },
    ],
  },
  {
    name_jp: '働く環境',
    name_en: 'WORK ENVIRONMENT',
    href: '#',
    list: [
      {
        icon: 'schedule',
        title: 'フレックスタイム制度',
        desc: 'フレックスタイム制度（コアタイム：11時〜15時）を導入しており、柔軟に働くことができます。',
      },
      {
        icon: 'person_celebrate',
        title: '年次有給休暇',
        desc: '勤続年数に応じて毎年4月に年次有給休暇を付与しています。また、中途入社でも入社初月より付与＆取得が可能です。',
      },
      {
        icon: 'child_care',
        title: '産前・産後休業、育児休業制度',
        desc: '産前・産後休業や育児休業制度を設けており、性別や役職問わず、出産や育児をサポートできる体制を整えています。',
      },
      {
        icon: 'medical_information',
        title: '関東ITソフトウェア健康保険組合',
        desc: '全国の保養施設や宿泊施設、その他レクリエーションを組合の価格で利用することができます。',
      },
      {
        icon: 'home',
        title: '不動産仲介業者連携',
        desc: '賃貸契約の際、提携の不動産仲介業者を紹介する制度です。割引や特典を受けることができます。',
      },
      {
        icon: 'health_and_beauty',
        title: '美容医療施術割引',
        desc: '指定の美容クリニックにて、一部の施術を割引価格で利用できます。',
      },
      {
        icon: 'local_drink',
        title: 'オフィスドリンク',
        desc: 'オフィスに設置しているコーヒーマシンを自由に利用することができます。',
      },
      {
        icon: 'takeout_dining',
        title: 'オフィスグリコ',
        desc: 'スナック菓子やアイスなどをオフィスで購入することができます。',
      },
      {
        icon: 'shopping_cart',
        title: 'SS Market',
        desc: 'ecforce導入クライアントや自社D2Cブランドの商品を補助金を利用して購入できます。（※補助金上限あり）',
      },
      {
        icon: 'home_work',
        title: '引越し手当',
        desc: '会社近隣に居住地を移動する社員に対して会社から一定金額を補助します。',
      },
      {
        icon: 'monitoring',
        title: '従業員持株会',
        desc: '社員（従業員）が自社の株式を共同購入する制度です。加入は任意となっています。',
      },
    ],
  },
  {
    name_jp: 'コミュニケーション',
    name_en: 'communication',
    list: [
      {
        icon: 'meeting_room',
        title: 'バーチャルオフィス',
        desc: 'リモートワークでも気軽にコミュニケーションが取れる環境を整えています。',
      },
      {
        icon: 'sports_esports',
        title: 'SS Club',
        desc: '公式部活動制度である「SS Club」は、共通の趣味を持つメンバー同士が集まり、部活動ごと定期的に活動しています。',
      },
      {
        icon: 'movie',
        title: 'SS Channel',
        desc: '社内向けコンテンツとして、カジュアルなコンテンツから業界トピックまで、会社や事業、業界への理解が深まる動画を配信しています。',
      },
      {
        icon: 'restaurant',
        title: '社内交際費補助制度',
        desc: '歓送迎会や懇親会など社内コミュニケーションを目的としたイベントに補助制度を利用できます。',
      },
    ],
  },
  {
    name_jp: '組織開発',
    name_en: 'organization development',
    list: [
      {
        icon: 'groups',
        title: '月次全社定例',
        desc: '社員全員が集まる定例を毎月実施し、各部署の取り組みや実績などを報告しています。',
      },
      {
        icon: 'partner_exchange',
        title: 'KICK OFF',
        desc: '一年の振り返りや中長期戦略の発表、SUPER STUDIO AWARD（表彰）や懇親会を全社員参加型で年1回実施します。',
      },
      {
        icon: 'military_tech',
        title: 'SUPER STUDIO AWARD',
        desc: 'CULTUREを体現し、社内にインパクトを与える功績を残したメンバーをKICK OFFで表彰します。',
      },
      {
        icon: 'diversity_1',
        title: 'CULTURE研修',
        desc: 'SUPER STUDIOのCULTUREの理解を深める研修プログラムです。',
      },
      {
        icon: 'local_fire_department',
        title: 'IgniteS!',
        desc: 'マネージャーを対象にした実践型の育成プログラムを定期的に実施しています。',
      },
      {
        icon: '/images/benefit/icon_withUs.webp',
        typeImg: true,
        title: 'WithUS!',
        desc: 'SUPER STUDIOの紹介やリファラル採用を目的とし、社外の友人や知人とコミュニケーションを図るための制度です。',
      },
    ],
  },
]
export const CULTURE_FV = [
  {
    title: '変わっていくことを楽しもう',
    image: '/images/culture/img_01.webp',
    comments: [
      {
        title:
          '組織やプロダクトが急成長していく中で、自分自身も変えていくことが非常に大切だと考えているから。',
        tag: '#エンジニア',
      },
      {
        title:
          '自分でできることが増えてきた時こそ、「更に高みを目指そう！」と変化を楽しめるから。',
        tag: '#カスタマーサポート',
      },
      {
        title:
          '変化が心地よい刺激となり、そういう環境の方がポジティブな思考で仕事に取り組めるから。',
        tag: '#採用',
      },
      {
        title:
          '変化の激しい業界だからこそ、変わっていくことを楽しむ姿勢がとても重要だと思うから。',
        tag: '#セールス',
      },
    ],
  },
  {
    title: 'オーナーシップを持とう',
    image: '/images/culture/img_02.webp',
    comments: [
      {
        title:
          '事業を大きくするためには、一人ひとりがオーナーシップと責任を持って取り組む必要があるから。',
        tag: '#プロジェクトサポート',
      },
      {
        title:
          '仕事への責任感が生まれ、人が成長する機会をより多く持つことができると思うから。',
        tag: '#経営企画',
      },
      {
        title:
          'あらゆる業務を自分ごととして捉えることで、仕事に対して力を出し切ることができると思うから。',
        tag: '#マーケティング',
      },
      {
        title:
          'オーナーシップを持って行動できる人が多いほど、その組織の推進力は大きいと思うから。',
        tag: '#業務Ops',
      },
    ],
  },
  {
    title: '一人一人が細部にこだわろう',
    image: '/images/culture/img_03.webp',
    comments: [
      {
        title: '保守や運用の観点から、徹底的に細部にこだわる必要があるから。',
        tag: '#エンジニア',
      },
      {
        title:
          '人数が増えても、一人ひとりが細部まで心がけることで素晴らしい仕事を成し遂げることができるから。',
        tag: '#カスタマーサクセス',
      },
      {
        title:
          'クライアント1社1社と密に議論しながらソリューションを提案していきたいと考えているから。',
        tag: '#セールス',
      },
      {
        title:
          '個々が細部にこだわることで、組織全体で調和が取れてより良い会社になると思うから。',
        tag: '#PMM',
      },
    ],
  },
  {
    title: '現場の第一人者になろう',
    image: '/images/culture/img_04.webp',
    comments: [
      {
        title:
          '何事も自分ごと化して情熱を持って取り組めることや、それを応援する文化が好きだから。',
        tag: '#エンジニア',
      },
      {
        title:
          'カスタマーサポートとして常に物事を自分ごととして捉え、責任を持って行動することを心がけているから。',
        tag: '#カスタマーサポート',
      },
      {
        title:
          '年齢や性別問わず、全員が第一人者になれるチャンスがある環境だから。',
        tag: '#カスタマーサポート',
      },
      {
        title:
          '自分で仕事を作っていく文化が強いので、誰にでも輝けるチャンスがあり、その道の第一人者になれる環境があるから。',
        tag: '#カスタマーサクセス',
      },
    ],
  },
  {
    title: '確信を取りにいこう',
    image: '/images/culture/img_05.webp',
    comments: [
      {
        title:
          '短期的な解決よりも、1年後、3年後、その先までを見据えた解決策を提示することを追求していきたいから。',
        tag: '#エンジニア',
      },
      {
        title:
          '対外的に会社の魅力を発信するためには、自ら情報収集してプロダクトや会社への確信を得ることが必要だから。',
        tag: '#広報',
      },
      {
        title: '確信がないと自信を持って提案することができないと思うから。',
        tag: '#セールス',
      },
      {
        title:
          '何事も絶対的な正解がないなかで、自ら確信を取りに行く姿勢が何よりも大切だと思うから。',
        tag: '#マーケティング',
      },
    ],
  },
  {
    title: '情熱と思いやりでヒトを動かそう',
    image: '/images/culture/img_06.webp',
    comments: [
      {
        title: '情熱を持っていなければ何かを変えることができないと思うから。',
        tag: '#PdM',
      },
      {
        title:
          '情熱と思いやりを持って人の心に訴えかけることで、納得感を持って仕事をしたいから。',
        tag: '#エンジニア',
      },
      {
        title:
          '一人でもチームでも、高いモチベーションと情熱を持つことでより良い成果が出ると思うから。',
        tag: '#デザイナー',
      },
      {
        title:
          '常に相手を考え、情熱を持って伝えていくことが仕事をする上で重要だと思うから。',
        tag: '#エンジニア',
      },
    ],
  },
  {
    title: '三方良しの選択を追求しよう',
    image: '/images/culture/img_07.webp',
    comments: [
      {
        title:
          '価値のあるプロダクトをつくり、クライアントに満足していただき、社会に還元することが商売の本質だと思うから。',
        tag: '#新規事業開発',
      },
      {
        title: 'クライアントと長期的に良い関係を築くことを大事にしているから。',
        tag: '#エンジニア',
      },
      {
        title:
          'クライアントだけでなく、自社のメンバーやエンドユーザーなど関係するすべての人にワクワクを届けたいから。',
        tag: '#エンジニア',
      },
      {
        title:
          'プロダクトを導入していただいて終わりでなく、クライアントと一緒に成長し続けることを大事にしているから。',
        tag: '#カスタマーサクセス',
      },
    ],
  },
]

export const NEWGRADUATE_CAREERS = [
  {
    text: '働く環境',
    textColor: 'text-white',
    imagePc: '/images/img_careers_02_pc.webp',
    imageSp: '/images/img_careers_02_sp.webp',
    href: '/workstyle',
  },
  {
    text: '事業内容',
    textColor: 'text-white',
    imagePc: '/images/img_careers_03_pc.webp',
    imageSp: '/images/img_careers_03_sp.webp',
    href: '/service',
  },
]
export const MEDIA_LIST = [
  {
    img: '/images/interview/note.webp',
    name: 'note',
    href: 'https://note.com/super_studio/m/m557a7e30ad51',
  },
  {
    img: '/images/interview/wantedly.webp',
    name: 'Wantedly',
    href: 'https://www.wantedly.com/companies/super-studio',
  },
  {
    img: '/images/interview/zenn.webp',
    name: 'SUPER STUDIO<br class="md:hidden"/> テックブログ',
    href: 'https://zenn.dev/p/superstudio',
  },
  {
    img: '/images/interview/cocoda.webp',
    name: 'SUPER STUDIO<br class="md:hidden"/> デザイナーブログ',
    href: 'https://cocoda.design/teams/super-studio',
  },
]
export const OUR_VOICE_IGNITE_IN_DATA = [
  {
    text: '自分の仕事に責任を持って取り組み、会社を良くし成長させることに真剣に取り組んでいる印象があります。',
    tag: '#エンジニア',
    image: '/images/face_01.webp',
  },
  {
    text: '業界のトレンドや新たなツールへの興味が強い方が多く、部署間で業務内容が異なってもコミュニケーションを取って共有している方が多い印象。',
    tag: '#セールス',
    image: '/images/face_02.webp',
  },
  {
    text: '建設的な話し合いができ、メンバーの納得感を大切にして業務やマネジメントを行っている印象。',
    tag: '#エンジニア',
    image: '/images/face_03.webp',
  },
  {
    text: '新しいことに対するキャッチアップが早く、変化をし続けるところが魅力だと感じます。<br/>また、誠実な人が多く人間関係の悩みが少ない印象です。',
    tag: '#デザイナー',
    image: '/images/face_04.webp',
  },
  {
    text: '自分の考えを持ちながらも、協調の姿勢を大事にしている人が多い印象。<br/>一緒に仕事して心地よい人が多いと感じる。',
    tag: '#エンジニア',
    image: '/images/face_05.webp',
  },
  {
    text: '情熱を持った人が多く、健全なコミュニケーションができる印象。<br/>また、挑戦することに対してポジティブな反応が多く、協力的な人が多い。',
    tag: '#経営企画',
    image: '/images/face_01.webp',
  },
  {
    text: '優しい人が多いですが、感情で仕事を進めることはなく、ロジカルシンキングが浸透していると感じます。',
    tag: '#カスタマーサポート',
    image: '/images/face_02.webp',
  },
  {
    text: '自身の業務だけではなく、多角的に他部署を巻き込んでナレッジの共有や連携をしている文化を感じます。',
    tag: '#セールス',
    image: '/images/face_03.webp',
  },
  {
    text: '目の前にある仕事だけではなく、先を見据えビジネスが成功するために行動している人が多い印象。',
    tag: '#エンジニア',
    image: '/images/face_04.webp',
  },
  {
    text: '変化を恐れず、新しい取り組みにもポジティブに反応してくれる人が多く、社員全体としても親切な人が多い印象。',
    tag: '#プロダクトマネジメント',
    image: '/images/face_05.webp',
  },
]

export const WHY_JOIN_COMPANY = [
  {
    text: '業界の中でも先進的で、EC事業に必要不可欠なプロダクトを展開していることに魅力を感じた。',
    image: '/images/face_03.webp',
  },
  {
    text: '社員の自然体な雰囲気を感じ、良いコミュニケーションが取れると思いました。',
    image: '/images/face_01.webp',
  },
  {
    text: '自分が描くキャリアを実現できると思ったからです。また、会社の未来にもワクワクしました。',
    image: '/images/face_04.webp',
  },
  {
    text: '成長中の企業であるため裁量を持って色々な経験ができると思い入社しました。',
    image: '/images/face_02.webp',
  },
  {
    text: 'メンバー全員が一丸となってお客様へのサービス価値を提供できる環境だと感じたからです。',
    image: '/images/face_01.webp',
  },
  {
    text: '新しい機能やサービスが開発されていくスピード感に魅力を感じました。',
    image: '/images/face_05.webp',
  },
]

export const MOMENT_HEART_FIRE = [
  {
    text: 'ecforceの機能を組み合わせて、お客様にとって最適な施策を提案できたとき。',
    image: '/images/face_03.webp',
  },
  {
    text: '高いチーム目標を協力して達成できたとき。最後まで諦めずに取り組んだからこそ、とても嬉しかったです。',
    image: '/images/face_01.webp',
  },
  {
    text: 'クライアントのEC事業の規模が大きくなり、自社サービスの介在価値を感じたとき。',
    image: '/images/face_04.webp',
  },
  {
    text: 'メンバー全員で議論し、最適なコンセンサスを得られたとき。それをクライアントに喜んでいただけたとき。',
    image: '/images/face_02.webp',
  },
  {
    text: '自分が考えた施策が軌道に乗り、自社D2C事業で売上が大きく伸びたとき。',
    image: '/images/face_01.webp',
  },
  {
    text: '難易度の高い案件にチャレンジできたとき。自分のスキルをフル活用して挑戦することができました。',
    image: '/images/face_05.webp',
  },
]

export const ENTRY_NEWGRADUATE = [
  // {
  //   id: 1,
  //   title: '【2026卒】サマーインターン',
  //   pcImage: '/images/newgraduate/img_entry_01.webp',
  //   spImage: '/images/newgraduate/img_entry_01.webp',
  //   href: 'https://public.n-ats.hrmos.co/superstudio/jobs/2001508382998126592',
  // },
  {
    id: 1,
    title: '【2026卒】ビジネス総合職',
    pcImage: '/images/newgraduate/img_entry_02.webp',
    spImage: '/images/newgraduate/img_entry_02.webp',
    href: 'https://www.career-cloud.asia/26/entry/job/offer/super-studio/detail/99',
  },
]
